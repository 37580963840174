import * as React from 'react'
import Container from 'react-bootstrap/Container'
import { Card, Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { Divider } from '@mui/material'

const IfYouBuildIt = () => {
  return (
    <section id="ifYouBuildIt">
      <Container fluid className="px-0">
        <Row className="g-0">
          <Col md="6" lg="3" className="bg-primary text-white">
            <div className="p-2">
              <h3 className="mb-2 h4">High protein (48%) and low fat (46%) are the top two most sought after menu options for diners.</h3>
              <p className="mb-0">Mintel, Healthy Dining Trends, US - 2022, Page 24</p>
            </div>
          </Col>
          <Col md="6" lg="3" className="bg-danger text-warning">
            <div className="p-2">
              <h3 className="mb-2 h4">According to the Datassential’s 2023 Food Trends, 40% of consumers plan to purchase animal-free meat products in 2023.</h3>
              <p className="mb-0">2023 Food Trends, Dataessential</p>
            </div>
          </Col>
          <Col md="6" lg="3" className="bg-success">
            <div className="p-2">
              <h3 className="mb-2 h4">73% of consumers agree that they want animal-free meat to taste like animal-based versions.</h3>
              <p className="mb-0">2023 Consumer Insights, Good Food Institute</p>
            </div>
          </Col>
          <Col md="6" lg="3" className="bg-info text-white">
            <div className="p-2">
              <h3 className="mb-2 h4">People are ready for more animal-free options. Retail of plant-based chicken cutlets doubled over the past three years since 2020.</h3>
              <p className="mb-0">Plant Based Meat Association, 2021 U.S. Retail sales data for the plant-based foods industry</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default IfYouBuildIt
