import React, { useEffect } from 'react'
import { gsap } from 'gsap'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const ScrollingImages = (props) => {
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "scroller"}}) {
        nodes {
          childImageSharp {
            gatsbyImageData(height: 300)
          }
        }
      }
    }
  `)

  useEffect(() => {
    const tickerTl = gsap.timeline({
      defaults: { duration: 18, ease: 'none' },
      repeat: -1,
    })

    tickerTl.to('.image-scroller-set-wrapper', { xPercent: '-100' })
  }, [])

  const imageScroller = (
    <div className="image-scroller-set-wrapper">
      {
        images.allFile.nodes.map((node, index) => {
          const image = getImage(node.childImageSharp)
          const alt = 'Meati foodservice thumbnail'
          const wrapperClass = 'rounded-4 image-scroller-image'
          const imgClass = 'rounded-4'
          return (
            <GatsbyImage
              image={image}
              alt={alt}
              className={`flex-shrink-0 mx-12 ${wrapperClass}`}
              imgClassName={imgClass}
              key={index}
            />
          )
        })
      }
    </div>
  )

  return (
    <section id="scrollingImages" className="bg-primary pb-2 position-relative">
      <div className="image-scroller-wrapper d-flex flex-nowrap">
        {imageScroller}
        {imageScroller}
      </div>
    </section>
  )
}

export default ScrollingImages
