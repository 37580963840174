import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import arrow from '../../images/arrow-right-white.svg'

const CulinaryButtonLink = (props) => {
  const data = useStaticQuery(graphql`
    {
      allWp {
        nodes {
          meatiSiteOptions {
            siteGlobalOptions {
              culinaryKit {
                downloadType
                file {
                  localFile {
                    extension
                  }
                  slug
                }
                link
                buttonText
              }
            }
          }
        }
      }
    }
  `)

  const button = data.allWp.nodes[0].meatiSiteOptions.siteGlobalOptions.culinaryKit

  const href = (button.downloadType === 'link' && button.link) ? button.link
    : (button.downloadType === 'file' && button.file) ? `/files/${button.file.slug}.${button.file.localFile.extension}`
      : '#'

  return (
    <a
      className="btn btn-lg btn-primary font-swish fs-2 text-center download-culinary-guide d-flex align-items-center justify-content-center py-1 ad-el-tracked"
      data-ad-element-type="cta button"
      data-ad-text={button.buttonText}
      data-ad-button-id="version 1"
      data-ad-button-location="body"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {button.buttonText}
      <img src={arrow} alt="arrow-icon" className="ms-1" />
    </a>
  )
}

export default CulinaryButtonLink
