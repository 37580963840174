import * as React from 'react'
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faExternalLink } from '@fortawesome/pro-regular-svg-icons'

const SellCTA = () => {
  return (
    <section id="sellCTA" className="py-3 py-sm-3 py-lg-4 py-xl-5 text-center bg-warning">
      <Container className="d-flex flex-column align-items-center">
        <h2 className="mb-1">Want Meati?</h2>
        <p className="lead mb-1">Get Meati on your shelves and menus today.</p>
        <div className="d-flex align-items-center mb-md-2">
          <a
            href="https://www.webstaurantstore.com/vendor/meati.html"
            target="_blank"
            role="button"
            className="btn btn-lg btn-outline-dark me-12"
            data-ad-element-type="sell CTA"
            data-ad-text="Order your first case"
            data-ad-button-location="body"
            data-ad-button-id="version 1"
          >
            <FontAwesomeIcon className="me-12" icon={faExternalLink} />
            Order your first case
          </a>
          <a 
            href="#sellForm" 
            role="button" 
            className="btn btn-lg btn-dark"
            data-ad-element-type="sell CTA"
            data-ad-text="Partner with us"
            data-ad-button-location="body"
            data-ad-button-id="version 1"
          >
            <FontAwesomeIcon className="me-12" icon={faEnvelope} />
            Partner with us
          </a>
        </div>
      </Container>
    </section>
  )
}

export default SellCTA
