import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ButtonLink from '../common/button-link'

const Awards = (props) => {
  const awards = useStaticQuery(graphql`
    query {
      allAwardsYaml(filter: {logo_white: {id: {ne: null}}}) {
        nodes {
          name
          logo_white {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)

  return (
    <section id="allStarCast" className="py-2 py-md-3 py-lg-4 bg-primary">
      <Container className="px-3 px-xl-6">
        <Row className="g-5 justify-content-center">
          {
            awards.allAwardsYaml.nodes.map((node, index) => (
              <Col xs="6" md="4" key={`logo-${index}`} className="align-items-center d-flex col-md-auto">
                <GatsbyImage
                  image={getImage(node.logo_white)}
                  alt={`Meati partner - ${node.name} logo`}
                  className="mx-md-12"
                />
              </Col>
            ))
          }
        </Row>
      </Container>
    </section>
  )
}

export default Awards
