import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../images/icons/circle-check.svg'

const FDSVFeatures = () => {
  /*
  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    gsap.to(window, { duration: 0.35, scrollTo: 0 });

    const blocks = gsap.utils.toArray(".fdsv-feature"),
      textblocks = gsap.utils.toArray(".fdsv-feature-text");

    let mm = gsap.matchMedia();
    //For smaller than tablet, trigger animation on each block's position
    mm.add("(max-width: 767px)", () => {

      blocks.forEach((block, i) => {
        let tl = gsap.timeline({
          scrollTrigger: {
            start: "top center",
            trigger: block
          }
        })
        tl
          .to(textblocks[i], { autoAlpha: 1, rotationY: 0, duration: 1, ease: "back.out(1.7)" }, ">+0.5")
      })
    });

    //For tablet and larger, trigger animation on container block
    mm.add("(min-width: 768px)", () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          start: "top bottom-=100",
          trigger: ".fdsv-features"
        }
      })
      blocks.forEach((block, i) => {
        tl
          .to(textblocks[i], { autoAlpha: 1, rotationY: 0, duration: 1, ease: "back.out(1.7)" }, ">+0.5")
      })
    });

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll();
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill();
        })
      }
    };

  }, [])
  */

  const features = useStaticQuery(graphql`
    query {
      allFdsvFeaturesYaml {
        nodes {
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData(
                width: 440
                placeholder: BLURRED
                formats: NO_CHANGE
                layout: CONSTRAINED
              )
            }
          }       
        }
      }
    }
  `)

  return (
    <section className="pt-2 pt-md-3 pb-md-1 py-lg-3 fdsv-features bg-dark text-light">
      <Container>
        <Row className="gx-xl-4 align-items-lg-center">
          <Col md="6">
            <h2 className="mb-0 text-light font-swish d-flex flex-column" id="getMeatiMacros">
              <span className="mb-n2 mb-n3 ps-1 ps-xl-2">Get</span>
              <div className="video-container">
                <iframe
                  src="https://player.vimeo.com/video/803335442?h=d788618290&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&background=1&controls=0"
                  allow="autoplay;"
                  title="meati macro shots"
                />
                <script src="https://player.vimeo.com/api/player.js" />
              </div>
              <span className="mt-n3 mt-lg-n4 mt-xl-n5 align-self-end pe-1 pe-xl-2">meati</span>
            </h2>
          </Col>
          <Col md="6">
            {
              features.allFdsvFeaturesYaml.nodes.map((node, index) => {
                return (
                  <div key={`feature-${index}`} md="4" className="my-2 d-flex align-items-start me-xxl-4 me-xxxl-6">
                    <img src={Check} alt="check icon" className="me-1 me-xl-2 mt-md-14 flex-shrink-0" />
                    <div>
                      <h2>{node.title}</h2>
                      <p className='lead mb-0'>{node.description}</p>
                    </div>
                    
                  </div>
                )
              })
            }
          </Col>
        </Row>
      </Container>
    </section>
    
  )
}

export default FDSVFeatures
